var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-block" }, [
    _c(
      "div",
      { staticClass: "source" },
      [
        _c(
          "el-form",
          {
            ref: "formData",
            staticClass: "demo-form",
            attrs: {
              model: _vm.formData,
              rules: _vm.rules,
              "label-position": "left"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "Enabled", prop: "enabled" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.formData.enabled,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "enabled", $$v)
                    },
                    expression: "formData.enabled"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Title", prop: "title" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "title",
                    required: "",
                    maxlength: "30",
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.formData.title,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "title", $$v)
                    },
                    expression: "formData.title"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Message", prop: "message" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    rows: 3,
                    autosize: { minRows: 3, maxRows: 4 },
                    name: "message",
                    maxlength: "100",
                    "show-word-limit": "",
                    required: ""
                  },
                  model: {
                    value: _vm.formData.message,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "message", $$v)
                    },
                    expression: "formData.message"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Image", prop: "image" } },
              [
                _c("file-upload", {
                  attrs: { "file-list": _vm.image },
                  on: { change: _vm.imageUpload }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "Page", prop: "page" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      name: "page",
                      clearable: "",
                      required: "",
                      filterable: "",
                      "auto-complete": "off"
                    },
                    on: { change: _vm.resetParams },
                    model: {
                      value: _vm.formData.page,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "page", $$v)
                      },
                      expression: "formData.page"
                    }
                  },
                  _vm._l(_vm.pageList, function(page) {
                    return _c("el-option", {
                      key: page.key,
                      attrs: { label: page.value, value: page.key }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _vm.formData.page === "products"
              ? _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { label: "Category", required: "", prop: "params" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          name: "Category",
                          placeholder: "Select Category"
                        },
                        model: {
                          value: _vm.formData.params,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "params", $$v)
                          },
                          expression: "formData.params"
                        }
                      },
                      _vm._l(_vm.categoryList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.page === "productDetails"
              ? _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: { label: "Products", required: "", prop: "params" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          remote: "",
                          "remote-method": _vm.getProductList,
                          name: "Product",
                          placeholder: "Enter Product Name"
                        },
                        model: {
                          value: _vm.formData.params,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "params", $$v)
                          },
                          expression: "formData.params"
                        }
                      },
                      _vm._l(_vm.productList, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.formData.page === "Link"
              ? _c(
                  "el-form-item",
                  { attrs: { label: "Link", prop: "link" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { type: "link", name: "link", required: "" },
                        model: {
                          value: _vm.formData.link,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "link", $$v)
                          },
                          expression: "formData.link"
                        }
                      },
                      [
                        _c("template", { slot: "prepend" }, [
                          _vm._v(" https:// ")
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              { attrs: { label: "Send to All Users", prop: "sendToAll" } },
              [
                _c("el-switch", {
                  on: { change: _vm.sendToAllToggled },
                  model: {
                    value: _vm.formData.sendToAll,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "sendToAll", $$v)
                    },
                    expression: "formData.sendToAll"
                  }
                })
              ],
              1
            ),
            !_vm.formData.sendToAll
              ? [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Users Role" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            name: "usersRoleId",
                            placeholder: "Select Role"
                          },
                          model: {
                            value: _vm.formData.usersRoleId,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "usersRoleId", $$v)
                            },
                            expression: "formData.usersRoleId"
                          }
                        },
                        _vm._l(_vm.userRoleList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Platform", prop: "platform" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            name: "platform",
                            clearable: "",
                            filterable: "",
                            "auto-complete": "off",
                            placeholder: "Select Platform"
                          },
                          model: {
                            value: _vm.formData.platform,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "platform", $$v)
                            },
                            expression: "formData.platform"
                          }
                        },
                        _vm._l(_vm.platformList, function(item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading"
                      },
                      { name: "waves", rawName: "v-waves" }
                    ],
                    attrs: { type: "success" },
                    on: { click: _vm.submitForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    attrs: { type: "reset" },
                    on: { click: _vm.resetForm }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")]
                )
              ],
              1
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }